<template>
  <center>
    <h1>404</h1>
    <p>Page Not Found</p>
    <p>
      <router-link to="/">Back to home</router-link>
    </p>
  </center>
</template>

<script>
export default {
  name: "NotFound",
};
</script>

<style scoped>
center {
  margin: 15vw;
}

h1 {
  color: var(--border);
  font-size: 2em;
  font-weight: 700px;
}
</style>
