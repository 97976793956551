<template>
  <nav class="navbar navbar-default">
    <div id="mySidepanel" class="sidepanel">
      <div class="mobile_menu">
        <div class="Close">
          <a href="javascript:void(0)" class="closebtn" @click="closeNav()"
            >×</a
          >
        </div>
        <ul>
          <li @click="closeNav()"><router-link to="/">Home</router-link></li>
          <li @click="closeNav()">
            <router-link to="/checkups">Checkups</router-link>
          </li>
          <li @click="closeNav()">
            <router-link to="/services">Services</router-link>
          </li>
          <li @click="closeNav()">
            <router-link to="/facilities">Facilities</router-link>
          </li>
          <li @click="closeNav()">
            <router-link to="/about">About</router-link>
          </li>
          <li @click="closeNav()">
            <router-link to="/contact">Contact</router-link>
          </li>
        </ul>
      </div>
    </div>
    <div class="container">
      <div class="navbar-header">
        <button @click="openNav()" type="button" class="navbar-toggle">
          <span class="icon-bar"></span>
          <span class="icon-bar"></span>
          <span class="icon-bar"></span>
        </button>
        <router-link to="/">
          <li class="navbar-brand">
            <div class="nav-logo">
              <img src="../assets/images/Artboard 3.webp" alt="" />
            </div>
            <div>
              <h4>Royal Hospital</h4>
              <p>Love and Care</p>
            </div>
          </li></router-link
        >
      </div>
      <div class="collapse navbar-collapse navbar-right" id="myNavbar">
        <ul class="nav navbar-nav">
          <li><router-link to="/">Home</router-link></li>
          <li><router-link to="/checkups">Checkups</router-link></li>
          <li><router-link to="/services">Services</router-link></li>
          <li><router-link to="/facilities">Facilities</router-link></li>
          <li><router-link to="/about">About</router-link></li>
          <li><router-link to="/contact">Contact</router-link></li>
        </ul>
      </div>
    </div>
  </nav>
</template>

<script>
export default {
  name: "Header",
  methods: {
    openNav() {
      document.getElementById("mySidepanel").style.width = "100%";
    },
    closeNav() {
      document.getElementById("mySidepanel").style.width = "0";
    },
  },
};
</script>

<style scoped>
.navbar {
  background-color: #3b4db1;
  width: 100%;
  height: 200px;
  z-index: 20;
  padding-top: 2%;
  border: none;
  border-radius: unset;
  margin: unset;
}
.navbar-nav li {
  overflow: hidden;
}
a li {
  list-style: none;
}
.navbar-brand {
  margin-top: 20px;
  display: flex;
  cursor: pointer;
  color: #fff;
}
.navbar-brand .nav-logo img {
  padding-right: 10px;
  padding-top: 5px;
}
.navbar-brand:hover {
  color: #fff;
}

.navbar-default .navbar-nav > li > a {
  color: #fff;
  font-size: 13px;
}
.navbar-default .navbar-nav > li > a:focus {
  color: #fff;
}
ul {
  margin-top: 40px;
}
.sidepanel {
  width: 0;
  position: fixed;
  z-index: 9999;
  height: 100%;
  top: 0;
  left: 0;
  background-color: #242424;
  overflow-x: hidden;
  transition: 0.5s;
}
.sidepanel ul > li {
  list-style: none;
  width: 80%;
  margin: unset;
  border-bottom: 1px solid #333232;
}
.sidepanel ul {
  margin: 20% auto;
  display: grid;
  place-items: center;
  padding: unset;
  width: 100%;
}

.sidepanel a {
  margin-top: 12px;
  margin-bottom: 12px;
  text-align: center;
  font-weight: 500;
  padding: 8px 8px 8px 24px;
  text-decoration: none;
  font-size: 16px;
  color: #fff;
  display: block;
  transition: 0.3s;
  cursor: pointer;
}

.Close a {
  margin: unset;
  font-weight: unset;
  font-size: 45px;
  padding-bottom: 30px;
  padding-left: 20px;
  transition: 100ms;
  transition-timing-function: ease-in-out;
}
.Close a:hover {
  color: #a3a3a3;
  font-size: 43px;
}
.sidepanel .closebtn {
  padding: 0px;
  position: absolute;
  top: 0;
  z-index: 1000;
  left: 5px;
}
</style>